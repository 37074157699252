.DateInput .DateInput_input{
    font-size: 15px;
    padding: 9.5px;
    line-height:normal;
    width: 100%;
    text-align: center;
}
.DateRangePickerInput__withBorder{
    /* border-radius: 4px; */
    width: 100%;
    display: flex;
    justify-content: center;
}
.DateRangePicker{
    width: 100%;
}

.select-store:hover{
    background-color: #A2F9F9;
}