
.zoomImage {
//   
  transition: transform .2s; /* Animation */
//   width: 200px;
//   height: 200px;
  margin: 0 auto;
}

.zoomImage:hover {
    -moz-transform: scale(1.25);
    -webkit-transform: scale(1.25);
    -o-transform: scale(1.25);
    -ms-transform: scale(1.25);
    -webkit-transform: scale(1.25);
    transform: scale(1.25);

    -webkit-transition: transform 1.5s ease-in-out;
    -moz-transition:transform 1.5s ease-in-out;
    -ms-transition:transform 1.5s ease-in-out;
}