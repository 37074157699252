.itemCard.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1 {
        // background-color: transparent;
        // box-shadow: none;
    box-shadow: none;
    .imageContainer img{
        border-radius: 5px;
    }
    .itemDetails{
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        width: 100%;
        height: 100%;
        .itemName{
            font-size: 1.14285714rem;
        }
        .itemDescription{
            font-size: 14px;
            line-height: 1.42857143;
            font-weight: 400;
            color: #9a9a9a;
            -webkit-box-flex: 1;
            flex: 1;
            word-break: break-word;
        }
    }
    // mobile phone size
    @media only screen and (max-width: 800px) {
        background-color: transparent;
        .itemPrice{
            font-size: 0.94rem;
            line-height: 1.5;
            font-weight: 500;
        }
    }

    // desktop size
    @media only screen and (min-width: 801px) {
        .imageContainer{
                height: 120px;
                width: 120px;
        }
        .itemPrice{
            font-size: 1.14285714rem;
            line-height: 1.5;
            font-weight: 500;
        }
    }
}